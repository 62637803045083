<template>
  <div>
    <v-row>
      <v-col cols="8">
        <h3 class="mb-4">Добавить рейс</h3>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="searchText"
          label="Поиск рейса"
          placeholder="Введите код рейса"
          class="pt-0 mt-0"
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredGraphics"
      :loading="loading"
      no-data-text="Нет записей"
      height="400"
      loading-text="Загрузка графиков"
      class="mb-4"
      fixed-header
      show-select
      disable-pagination
      hide-default-footer
    ></v-data-table>

    <v-row dense>
      <v-col>
        <v-btn
          outlined
          color="red"
          @click="cancelAdd"
        >
          Отмена
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="addGraphic"
          :disabled="selected.length === 0"
        >
          Добавить выбранные
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AllGraphics',
  props: {
    items: {
      type: Array,
      required: true,
    },
    graphics: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    selected: [],
    searchText: '',
    headers: [
      {
        text: 'Код рейса',
        value: 'tripcode',
      },
    ],
  }),
  computed: {
    filteredGraphics() {
      const re = new RegExp(this.searchText, 'i');

      // Показываем только те графики, которые еще не добавлены в договор
      return this.items.filter(graphic => {
        let index = this.graphics.findIndex(item => {
          return item.id === graphic.id;
        });

        if (this.searchText && !re.test(graphic.tripcode)) {
          return false;
        }

        return index === -1;
      });
    },
  },
  methods: {
    addGraphic() {
      this.$emit('onAdd', this.selected);

      this.selected = [];
    },
    cancelAdd() {
      this.selected = [];

      this.$emit('onCancel');
    },
  },
};
</script>
