<template>
  <div>
    <v-row>
      <v-col cols="9">
        {{ certificate.vccertificateregisterServicedrouteidCarrierOrgidShortname }}:   {{ certificate.vccertificateregisterServicedrouteidRouteRoutecode }} {{ certificate.vccertificateregisterServicedrouteidRouteRoutename }}
      </v-col>
          
    </v-row>
    <v-row>
      <v-col cols="4">
        <h3 class="mb-4">Рейсы для добавления</h3>
      </v-col>
      <v-spacer />

      <v-col cols="5">
        <v-autocomplete
          label="Версия расписания"
          v-model="currentSheet"
          item-value="id"
          :item-text="shortName"
          return-object
          :items="releaseSchedules"
          :loading="loadingRefs"
          @change="onSetReleaseSchedule"
          class="mt-0 pt-0"
        ></v-autocomplete>
      </v-col>

      <v-col>
        <v-btn
          color="primary"
          @click="addGraphic"
          :disabled="currentSheet === null"
        >
          Выбрать рейсы
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      height="400"
      loading-text="Загрузка рейсов"
      item-key="name"
      fixed-header
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <GraphicItem
          :item="item"
          @onRemove="removeGraphic"
        ></GraphicItem>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="confirmAdd"
          :loading="loadingConfirm"
          :disabled="items.length === 0"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import GraphicItem from "./GraphicItem";

  export default {
    name: "Graphics",
    components: {
      GraphicItem,
    },
    props: {
      certificate: {
        type: Object,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      releaseSchedules: {
        type: Array,
        required: true,
      },
      loadingRefs: {
        type: Boolean,
        required: true,
      },
      loadingConfirm: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'Рейс',
          value: 'tripcode',
        },
        {
          text: 'Версия расписания',
          value: 'version',
        },
        {
          text: 'Статус версии',
          value: 'verstatusName',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      currentSheet: null,
    }),
    methods: {
      shortName(item) {
        return item.version + '   ' + item.verstatusName;
      },
      addGraphic() {
        this.$emit('onAdd');
      },
      removeGraphic(graphic) {
        this.$emit('onRemove', graphic);
      },
      confirmAdd() {
        this.$emit('onConfirm');
      },
      onSetReleaseSchedule(item){
        this.$emit('setReleaseSchedule', item);
      }
    }
  }
</script>