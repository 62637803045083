<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Добавление рейсов в свидетельство № {{ selected.vccertificateregisterCardseries }} {{ selected.vccertificateregisterCardnumber }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Graphics
              :items="graphics"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :releaseSchedules="releaseSchedules"
              :certificate="selected"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllGraphics"
              @onRemove="removeGraphic"
              @setReleaseSchedule="onSetReleaseSchedule"
            ></Graphics>
          </v-tab-item>

          <v-tab-item>
            <AllGraphics
              :items="allSchedules"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :graphics="graphics"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllGraphics>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import Graphics from "./components/Graphics";
  import AllGraphics from "./components/AllGraphics";
  import CertificateRegisterService from '@/services/CertificateRegisterService';

  export default {
    name: "AddGraphic",
    components: {
      AllGraphics,
      Graphics
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      dialog: true,
      selected: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      graphics: [],
      allSchedules: [],
      releaseSchedules: [],
    }),
    created() {
      this.selected = this.collection.master.selected;
      this.loadingRefs = true;
      
      this.getReleaseSchedules();

      this.loadingRefs = false;
    },
    methods: {
      cancelAdd() {
        this.tab = 0;
      },
      showAllGraphics() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;
        items.forEach(item => {
          const index = this.graphics.findIndex(graphic => {
            return graphic.id === item.id;
          });

          if (index === -1) {
            this.graphics.push(item);
          }
        });
      },
      removeGraphic(item) {
        this.graphics = this.graphics.filter(graphic => graphic.id !== item.id)
      },
      confirmAdd() {
        this.loadingConfirm = true;
        this.saveSchedules(this.graphics);
      },
      async getReleaseSchedules() {
        this.loadingRefs = true;
        try {
          await CertificateRegisterService.getReleaseSchedules(this.selected.vccertificateregisterServicedrouteidRoute)
            .then(result => {
              this.releaseSchedules = result;
            })
            .catch(err => {
              _err('AddGraphic::getReleaseSchedules', err);
            })
            .finally(() => {
              this.loadingRefs = false;
            });
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список версий расписания',
            color: 'warning'
          });
          console.error('AddGraphic: getReleaseSchedules()', e);
          return;
        }
        this.loadingRefs = false;
          
      },
      async getAllNonCertificateSchedules(item) {
        this.loadingAll = true;
        var schedules = [];
        try {
          await CertificateRegisterService.getAllNonCertificateSchedules(item.id, this.selected.vccertificateregisterId)
            .then(result => {
              result.map(it => {
                it.version = item.version;
                it.verstatusName = item.verstatusName;
                it.releaseScheduleID = item.id;
                schedules.push({...it});
              });
              this.allSchedules = schedules;  
            })
            .catch(err => {
              _err('AddGraphic: getAllNonCertificateSchedules', err);
            })
            .finally(() => {
              this.loadingAll = false;
            });
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список рейсов',
            color: 'warning'
          });
          console.error('AddGraphic: getReleaseSchedules()', e);
          return;
        }
        this.loadingAll = false;
      },
      onSetReleaseSchedule(item) {
        this.getAllNonCertificateSchedules(item);
      },
      async saveSchedules(paramsArray) {
        let params = [];
        const certificateID = this.selected.vccertificateregisterId;
        
        for (let i = 0; i < paramsArray.length; i++) {
          params.push(
            {
              id: jet.utils.uuidv4(),
              certificateid: certificateID,
              scheduleid: paramsArray[i].id,
              releasescheduleid: paramsArray[i].releaseScheduleID,
            }
          );
        }
        const optionsQuery = {
          type: "query",
          query:"d56a0b48-f4ca-4158-986d-cc574c8e1eb1.add_schedule_into_certificate",
          params: {
            tenantid: this.$store.state.auth.subject.tenantId,
            userid: this.$store.state.auth.subject.id,
            paramslist: JSON.stringify(params),
          },
        };
        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Добавление рейсов в свидетельство № ${this.selected.vccertificateregisterCardseries}  ${this.selected.vccertificateregisterCardnumber} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: 'Не удалось добавить рейсы',
            color: 'warning'
          });
          console.error('AddGraphic: saveSchedules()', e);
          return;
        }
        this.loadingConfirm = false;

        this.dialog = false;
        this.collection.refresh(certificateID);
      }
    }
  }
</script>
