// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';

/**
 * Сервис для работы со свидетельствами
 */
export default class CertificateRegisterService {

  /**
   * Получение версий расписания по маршруту
   *
   * @param {String} routeID Маршрут
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getReleaseSchedules(routeID) {
    try {
      let query = `sin2:/v:54e55495-92e8-453a-ae02-567494757d32?filter=eq(field(".route"),param("${routeID}","id"))`

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('CertificateRegisterService::getReleaseSchedules', e);

      return [];
    }
  }
  /**
   * Получение всех рейсов по версии расписания, кроме присутствующих в свидетельстве
   *
   * @param {String} releasedScheduleID Версия расписания
   * @param {String} certificateID Свидетельство
   * @return {Promise<Array<Object>|*[]>}
   */
  static async getAllNonCertificateSchedules(releasedScheduleID, certificateID) {
    try {
      let query = `sin2:/v:f36bf0e9-39a3-438d-989f-4ef0dcecab70?filter=and(eq(field(".versionID"), param("${releasedScheduleID}", "id")),not(exists(("605c8a85-c59b-49ef-99bd-2581c15a6902"),"and(eq(super.field(\\".id\\"),field(\\".scheduleID\\")),eq(field(\\".certificateID\\"),param(\\"${certificateID}\\",\\"id\\"))))"))`
      

      return prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query
        )
      );
    } catch (e) {
      console.error('CertificateRegisterService::getAllNonCertificateSchedules', e);

      return [];
    }
  }
}